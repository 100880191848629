import { render, staticRenderFns } from "./NroViajeWellboatEspecie.vue?vue&type=template&id=5607781f&scoped=true"
import script from "./NroViajeWellboatEspecie.vue?vue&type=script&lang=js"
export * from "./NroViajeWellboatEspecie.vue?vue&type=script&lang=js"
import style0 from "./NroViajeWellboatEspecie.vue?vue&type=style&index=0&id=5607781f&prod&scoped=true&lang=css"
import style1 from "./NroViajeWellboatEspecie.vue?vue&type=style&index=1&id=5607781f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5607781f",
  null
  
)

export default component.exports